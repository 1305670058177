import React, { FunctionComponent, useEffect } from "react";

export interface ZoovuQuizInterface {
  Script: string;
  Id: string;
}

const ZoovuQuiz: FunctionComponent<ZoovuQuizInterface> = ({ Script, Id }) => {
  useEffect(() => {
    const scriptId = `zoovu-quiz-script-${Id}`;
    if (!document.getElementById(scriptId)) {
      const newScript = document.createElement("script");
      newScript.id = scriptId;
      newScript.src = Script;
      document.body.appendChild(newScript);
    }
  }, [Id, Script]);

  return <div id={Id}></div>;
};

export default ZoovuQuiz;
